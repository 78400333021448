import { Link } from "gatsby";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Button } from "../Primary/Button";

export interface ButtonAndShortTextProps {
  className?: string;
  children?: ReactNode;
  buttonTitle: string;
  buttonUrl?: string;
  shortText?: string;
  buttonType?: string;
  external?: boolean;
}

const ButtonAndShortTextInner = (props: ButtonAndShortTextProps) => {
  const {
    external = false,
    buttonType,
    buttonTitle,
    buttonUrl,
    shortText,
  } = props;
  return (
    <div className={props.className}>
      {external ? (
        <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
          <Button className="button" type={buttonType}>
            {buttonTitle}
          </Button>
        </a>
      ) : (
        <Link className="link" to={buttonUrl}>
          <Button className="button" type={buttonType}>
            {buttonTitle}
          </Button>
        </Link>
      )}
      <ButtonShortText>{shortText}</ButtonShortText>
    </div>
  );
};

export const ButtonAndShortText = styled(ButtonAndShortTextInner)`
  min-width: 200px;
  display: flex;
  justify-content: flex-start;
  flex-flow: column;
  align-items: center;
  margin: 10px 5px;

  &:hover {
    opacity: 0.6;
  }

  .link {
    width: 100%;
  }

  .button {
    display: flex;
    justify-content: center;
  }
`;

export const ButtonShortText = styled.div`
  color: ${(props) => props.theme.color.gray};
  margin-top: 15px;
  max-width: 80%;
  text-align: center;
`;
