import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import styled from "styled-components";
import { SectionTitle } from "../../SectionTitle/SectionTitle";
import { IntroTopText } from "./IntroTopText";
import { TelNumber } from "./RightContactInfo";

export interface FormSectionHeaderProps {
  className?: string;
}

const FormSectionHeaderInner = (props: FormSectionHeaderProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <SectionTitle textAlign="left">
        {t("contactFormSection.title")}{" "}
      </SectionTitle>
      <p>
        <i>"{t("contactFormSection.text")}"</i>
      </p>
      <p>
        <TelNumber>Poďme to zažiť</TelNumber>
      </p>
    </div>
  );
};

export const FormSectionHeader = styled(FormSectionHeaderInner)`
  margin-bottom: 40px;
`;
