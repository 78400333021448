import { Container, Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Section } from "../../Shared/Layout/components/Section/Section";
import { SectionTitle } from "../../Shared/SectionTitle/SectionTitle";
import { ArticleBox } from "./components/ArticleBox";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useNLastBlogPosts } from "../../Shared/hooks/useNLastBlogPosts";
import slugify from "slugify";
import { generatePostPath } from "../../../lib/utils";

export interface BlogSectionProps {
  className?: string;
}

const BlogSectionInner = (props: BlogSectionProps) => {
  const { image1 } = useStaticQuery(graphql`
    query ArticlesImages {
      image1: file(relativePath: { eq: "BlogSection/nastenka.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const posts = useNLastBlogPosts();

  const { t } = useTranslation("translations");

  return (
    <Section>
      <Container>
        <SectionTitle textAlign="left">{t("postsFromBlog.title")}</SectionTitle>
        <Grid container spacing={4} direction="row">
          {posts.map(post => (
            <Grid
              item
              key={post.title}
              container
              justify="center"
              alignItems="center"
              xs={12}
              sm={6}
              md={4}
            >
              <ArticleBox
                image={post.thumbnail.childImageSharp.fluid}
                title={post.title}
                description={post.teaser}
                linkText="Zobraziť viac"
                linkTo={generatePostPath(post.title)}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export const BlogSection = styled(BlogSectionInner)``;
