import Container from "@material-ui/core/Container";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ButtonAndShortText } from "../../../Shared/Button/ButtonAndShortText/ButtonAndShortText";
import { Section } from "../../../Shared/Layout/components/Section/Section";
import { muiTheme } from "../../../Shared/Theme/Theme";

export interface ButtonsWithSectionTextProps {
  className?: string;
}

interface IButtons {
  button: string;
}
const ButtonsWithTextSectionInner = (props: ButtonsWithTextSectionProps) => {
  const { t } = useTranslation("translations");

  const buttons: IButtons = t("leadershipAndSalesSkillsPage.buttons", {
    returnObjects: true
  });
  return (
    <Section className={props.className}>
      <Container>
        <ButtonsWrapper>
          {buttons.map((button: any, index) => (
            <ButtonAndShortText
              key={index}
              buttonTitle={button.buttonTitle}
              buttonUrl={button.buttonUrl}
              external
            />
          ))}
        </ButtonsWrapper>
      </Container>
    </Section>
  );
};

export const ButtonsWithTextSection = styled(ButtonsWithTextSectionInner)``;

const ButtonsWrapper = styled.div`
  display: flex;
  max-width: 50%;
  margin: 0 auto;
  justify-content: space-evenly;

  ${muiTheme.breakpoints.down("sm")} {
    flex-flow: column;
    align-items: center;
  }
`;
