import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { FiPhoneCall } from "react-icons/fi";
import { RiMailSendLine } from "react-icons/ri";
import styled from "styled-components";
import { routes } from "../../../../lib/routes";
import { Button } from "../../Button/Primary/Button";
import { SectionTitle } from "../../SectionTitle/SectionTitle";
import { muiTheme, theme } from "../../Theme/Theme";
import PhoneIcon from "../../../../assets/icons/phone_kontakt_icon.svg";
import EmailIcon from "../../../../assets/icons/email_kontakt_icon.svg";

export interface RightContactInfoProps {
  className?: string;
}

const TopSubTitle = styled.h3`
  color: ${(props) => props.theme.color.yellow};
`;

const ContactBox = styled(Grid)`
  margin-bottom: 20px !important;
`;
export const TelNumber = styled.a`
  font-weight: bold;
  font-size: 2.4rem;
  margin-bottom: 10px;
  display: flex;
`;
const RightContactInfoInner = (props: RightContactInfoProps) => {
  const { t } = useTranslation("translations");
  return (
    <div className={props.className}>
      <TopSubTitle>{t("contactFormSection.contactMe.subtitle")}</TopSubTitle>
      <SectionTitle textAlign="left">
        {t("contactFormSection.contactMe.title")}
      </SectionTitle>
      <ContactBox container spacing={1}>
        {/* //TODO: doplnit ikonky */}
        <Grid item>
          <img src={PhoneIcon} className="icon" />
        </Grid>
        <Grid item>
          <TelNumber href="tel:+421 908 112 639">
            {t("contact.phone")}
          </TelNumber>
          <p>{t("contact.available")}</p>
        </Grid>
      </ContactBox>
      <ContactBox container spacing={1}>
        <Grid item>
          <img src={EmailIcon} className="icon" />
        </Grid>
        <Grid item>
          <TelNumber href="mailto:brano@branohromada.com">
            {t("contact.email")}
          </TelNumber>
          <p>{t("contact.emailText")}</p>
        </Grid>
      </ContactBox>
      {/* <TopSubTitle>
        {t("contactFormSection.contactMe.appointmentThanks")}
      </TopSubTitle> */}
      {/* ///TODO: Interested in my E-book? (túto možnosť by som schoval pokiaľ nebudem mať ten e-book) */}
      {/* <SectionTitle textAlign="left">
        {t("contactFormSection.contactMe.ebook")}
      </SectionTitle>
      <Link to={routes.ebook.to}>
        <Button type="secondary">
          {t("contactFormSection.contactMe.ebookCta")}
        </Button>
      </Link> */}
    </div>
  );
};

export const RightContactInfo = styled(RightContactInfoInner)`
  .icon {
    font-size: 50px;
    color: ${theme.color.gray};
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 auto;
    transform: scale(2);

    ${muiTheme.breakpoints.down("xs")} {
      width: 20px;
    }
  }

  ${TopSubTitle} {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;
