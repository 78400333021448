import { Box, Container, Grid } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { Button } from "../../../Button/Primary/Button";
import { SectionTitle } from "../../../SectionTitle/SectionTitle";
import { Section } from "../Section/Section";

export interface TextSectionProps {
  className?: string;
  title: string;
  description: string;
  button?: string;
  buttonUrl?: string;

  darkBg?: boolean;
}

const TextSectionDescription = styled.p`
  text-align: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

const TextSectionInner = (props: TextSectionProps) => {
  const { title, description, button, buttonUrl = '' } = props;
  return (
    <Section darkBg={props.darkBg} className={props.className}>
      <Container>
        <Wrapper>
          <SectionTitle>{title}</SectionTitle>
          <TextSectionDescription>{description}</TextSectionDescription>
          {button && (
            <Link className="button-link" to={buttonUrl}>
              <Button type="primary">{button}</Button>
            </Link>
          )}
        </Wrapper>
      </Container>
    </Section>
  );
};

export const TextSection = styled(TextSectionInner)`
  ${Button} {
    margin-top: 30px;
  }

  .button-link {
    display: flex;
    justify-content: center;
  }
`;
