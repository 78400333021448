import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "../Theme/Theme";

interface SectionTitleProps {
  className?: string;
  textAlign?: "left" | "center" | "right";
  children?: ReactNode;
  color?: string;
}

const SectionTitleInner = (props: SectionTitleProps) => {
  const { children } = props;
  return <h2 className={props.className}> {children}</h2>;
};

export const SectionTitle = styled(SectionTitleInner)`
  font-size: 3.4rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: ${props => props.textAlign};
  color: ${props =>
    props.color === "white"
      ? props.theme.color.white
      : props.theme.color.primaryDark};
`;
