import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import React from "react";
import styled from "styled-components";
import { Section } from "../Layout/components/Section/Section";
import { Form } from "./components/Form";
import { FormSectionHeader } from "./components/FormSectionHeader";
import { IntroTopText } from "./components/IntroTopText";
import { RightContactInfo } from "./components/RightContactInfo";

export interface FormSectionProps {
  className?: string;
}

const FormSectionInner = (props: FormSectionProps) => {
  return (
    <Section className={props.className}>
      <Container>
        <FormSectionHeader />
        <Grid container spacing={6}>
          <Grid item md={8} sm={12}>
            <Form />
          </Grid>
          <Grid item md={4} sm={12} container alignItems="flex-start">
            <RightContactInfo />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};

export const FormSection = styled(FormSectionInner)`
  max-width: 100vw;
  overflow: hidden;
`;
