import GatsbyImage, { FluidObject } from "gatsby-image";
import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import removeMd from "remove-markdown";
import styled from "styled-components";
import { ViewMoreButton } from "../../../Shared/Button/ViewMore/ViewMoreButton";

export interface ArticleBoxProps {
  className?: string;
  title: string;
  description: string;
  image: FluidObject;
  linkText: string;
  linkTo: string;
}

const TopImageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  min-height: 230px;
  display: flex;
`;

const CustomLink = styled(Link)`
  height: 100%;
  transform: scale(1);
  transition-duration: 0.3s;
  transition-property: transform;
  transition-timing-function: ease-in-out;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.sectionBg};

  &:hover {
    transform: scale(1.01);
    transition-duration: 230ms;
    box-shadow: 0 6px 6px -10px rgba(0, 0, 0, 0.5);
  }
`;

const ArticleTitle = styled.h3`
  margin-bottom: 30px;
`;

const ArticleDescription = styled.p`
  height: 100%;
  margin-bottom: 25px;
`;

const Bottom = styled.div`
  padding: 30px 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ArticleBoxInner = (props: ArticleBoxProps) => {
  const { title, description, image, linkText, linkTo } = props;
  return (
    <CustomLink to={linkTo}>
      <TopImageWrapper>
        <GatsbyImage
          style={{
            height: "100%",
            width: "100%",
          }}
          fluid={image}
        />
      </TopImageWrapper>

      <Bottom>
        <ArticleTitle>{title}</ArticleTitle>
        <ArticleDescription>{removeMd(description)}</ArticleDescription>
        <ViewMoreButton>{linkText}</ViewMoreButton>
      </Bottom>
    </CustomLink>
  );
};

export const ArticleBox = styled(ArticleBoxInner)``;
