import React from "react";
import { BlogSection } from "../../components/Home/BlogSection/BlogSection";
import { ButtonsWithTextSection } from "../../components/Services/Leadership/ButtonsWithTextSection/ButtonsWithTextSection";
import { GetOutSection } from "../../components/Services/Leadership/GetOutSection.tsx/GetOutSection";
import { FormSection } from "../../components/Shared/FormSection/FormSection";
import { TopHeader } from "../../components/Shared/Layout/components/TopHeader/TopHeader";
import Layout from "../../components/Shared/Layout/Layout";

export interface LeadershipAndSalesSkillsProps {
  className?: string;
}

const LeadershipAndSalesSkills = () => (
  <Layout>
    <TopHeader title="Služby / Líderské a obchodné zručnosti" />
    <GetOutSection />
    <ButtonsWithTextSection />
    <BlogSection />
    <FormSection />
  </Layout>
);

export default LeadershipAndSalesSkills;
