import React, { ReactNode } from "react";
import styled from "styled-components";
import { theme } from "../../Theme/Theme";

export interface ButtonProps {
  children?: ReactNode;
  className?: string;
  type?: "primary" | "secondary";
  onClick?: () => void;
}

const ButtonInner = (props: ButtonProps) => {
  const { children, onClick, type = "primary" } = props;
  return (
    <div onClick={onClick} className={`${props.className} button--${type}`}>
      {children}
    </div>
  );
};

export const Button = styled(ButtonInner)`
  padding: 12px 30px;
  display: inline-flex;
  white-space: nowrap;
  color: white;
  font-weight: bolder;
  cursor: pointer;
  border-radius: 5px;
  font-size: 2rem;
  transition: all 230ms ease-in-out;
  border: 4px solid;

  justify-content: center;

  &.button {
    &--primary {
      background: ${theme.color.primaryDark};
      border-color: ${theme.color.primaryDark};

      &:hover {
        background: none;
        color: ${theme.color.primaryDark};
      }
    }
    &--secondary {
      background: ${theme.color.yellow};
      border-color: ${theme.color.yellow};

      &:hover {
        background: none;
        color: ${theme.color.yellow};
      }
    }
  }
`;
