import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TextSection } from "../../../Shared/Layout/components/TextSection/TextSection";

export interface GetOutSectionProps {
  className?: string;
}

const GetOutSectionInner = (props: GetOutSectionProps) => {
  const { t } = useTranslation("translations");

  return (
    <TextSection
      className={props.className}
      title={t("leadershipAndSalesSkillsPage.getOutSection.title")}
      description={t("leadershipAndSalesSkillsPage.getOutSection.description")}
    />
  );
};

export const GetOutSection = styled(GetOutSectionInner)``;
